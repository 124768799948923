import {ref} from '@vue/reactivity'
import FileService from '@/services/FileService'
import {File} from '@/composables/file/models'

const isFileLoading = ref(false)

export function file() {

    const storage = ref({} as {
        [i: number]: string | undefined
    })

    async function getFileBase64ById(fileId: number, lock= true): Promise<any> {
        isFileLoading.value = lock
        const fileBlob = await FileService.getFileById(fileId)
        const fileReader = new window.FileReader()
        fileReader.readAsDataURL(fileBlob.data)
        return new Promise((resolve) => {
            fileReader.onload = () => {
                storage.value[fileId] = fileReader.result?.toString()
                resolve(fileReader.result?.toString())
            }
        }).finally(
                () => isFileLoading.value = false
        )
    }

    async function getPassportBase64ById(fileId: number, lock= true): Promise<any> {
        isFileLoading.value = lock
        const fileBlob = await FileService.getPassport(fileId)
        const fileReader = new window.FileReader()
        fileReader.readAsDataURL(fileBlob.data)
        return new Promise((resolve) => {
            fileReader.onload = () => {
                storage.value[fileId] = fileReader.result?.toString()
                resolve(fileReader.result?.toString())
            }
        }).finally(
            () => isFileLoading.value = false
        )
    }

    function downloadOnDevice(file: File) {

        if (storage.value[file.id]) {
            download(storage.value[file.id]+'', file.name)
            return
        }

        getFileBase64ById(file.id).then((data)=>{
            download(data, file.name)
        })


    }

    function download(base64: string, name: string) {
        const link = document.createElement('a')
        link.href = base64
        link.setAttribute('download', name)
        link.click()
        URL.revokeObjectURL(base64)
        URL.revokeObjectURL(link.href)
    }

    return {

        // actions
        isFileLoading,
        getFileBase64ById,
        getPassportBase64ById,
        downloadOnDevice,

        // getters
        storage,
    }
}