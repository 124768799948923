import { createApp } from 'vue'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import App from './App.vue'
import mitt from 'mitt'
import Popper from 'vue3-popper'
import './registerServiceWorker'
import router from './router'
import { onlyDateFromDateString, onlyTimeFromDateString, dateTimeFromDateString, money, moneyRubles } from '@/plugins/filters'
import '@/plugins/config'

const emitter = mitt()

const app = createApp(App)
app.config.globalProperties.$filters = {
  onlyDateFromDateString,
  onlyTimeFromDateString,
  dateTimeFromDateString,
  money,
  moneyRubles
}
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(VueViewer)
app.component('Datepicker', Datepicker)
app.component('Popper', Popper)
app.mount('#app')