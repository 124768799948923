
import { Options, Vue} from 'vue-class-component'
import TheNavbar from '@/components/core/TheNavbar.vue'
import TheSidebar from '@/components/core/TheSidebar.vue'

@Options({
  name: 'LayoutSidebar',
  components: {
    TheNavbar,
    TheSidebar,
  },
})
export default class LayoutNavbarSidebar extends Vue {

}
