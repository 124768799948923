export const onlyDateFromDateString = (dateString: string): string => {
    try {
        const date = new Date(dateString)
        return isNaN(date.getDate()) ? 'Нет данных' : date.toLocaleDateString('ru-RU')
    } catch (error) {
        return 'Нет данных'
    }
}

export const onlyTimeFromDateString = (dateString: string): string => {
    try {
        const date = new Date(dateString)
        return date.toLocaleTimeString(['ru-RU'], {hour: '2-digit', minute: '2-digit'})
    } catch (error) {
        return ''
    }
}

export const dateTimeFromDateString = (dateString: string): string => {
    try {
        const date = new Date(dateString)
        return `${date.toLocaleDateString('ru-RU')} ${date.toLocaleTimeString(['ru-RU'], {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })}`
    } catch (error) {
        return ''
    }
}

export const money = (cops: number): string => {
    if (!cops) {
        return '0 руб.'
    } else {
        const rub = cops / 100
        return rub.toFixed(2).toString() + ' руб.'
    }
}

export const moneyRubles = (rubles: number): string => {
    const format = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    })
    return format.format(rubles)
}

export const validateEmail = (email: string): boolean =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    )
