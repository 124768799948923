export default class ErrorBag {

    private _bag: any;

    get bag(): any {
        return this._bag
    }

    set bag(value: any) {
        this._bag = value.errors
    }

    has(key: string): boolean {
        return this._bag && this._bag.hasOwnProperty(key)
    }

    get(key: string): any {
        if (!this._bag) return undefined
        return this._bag[key]
    }
}

//
// export class Error {
// }