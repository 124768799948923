
import {Options, setup, Vue} from 'vue-class-component'
import { file } from '@/composables/file'
import useEmitter from '@/composables/useEmitter'
import useChat from '@/composables/agents/chat'
// import { initHttp } from './http-common'
import ModalWindow from '@/components/core/ModalWindow.vue'

@Options({
  components: {
    ModalWindow,
  },
})

export default class App extends Vue {
  identifier = ''
  hasChat = true

  appContext = setup(() => {
    const { emitter } = useEmitter()
    const chat = useChat()
    return { emitter, ...chat, ...file()}
  })

  created(): void {
    window.addEventListener('storage', (e) => {
      if (e.key === 'mlm_main_tab') {
        this.appContext.stopChatUpdate()
      }
    })
  }

  mounted(): void {
    localStorage.setItem('mlm_main_tab', new Date().getTime().toString())
    this.appContext.startChatUpdate()
  }
}
