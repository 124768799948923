import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutSidebar from '@/layouts/LayoutSidebar.vue'
import {getCookie} from '@/composables/utils'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: LayoutSidebar,
    },
  },
  {
    path: '/agents',
    name: 'AgentsList',
    component: () => import('@/views/agents/List.vue'),
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/messages/dialogs',
    name: 'MessagesDialogs',
    component: () => import('@/views/messages/Dialogs.vue'),
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/agents/:agent',
    name: 'AgentsCard',
    component: () => import('@/views/agents/Card.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/agent_moderation',
    name: 'AgentsModeration',
    component: () => import('@/views/agents/ModerationList.vue'),
    props:{
      isModeration: true
    },
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: () => import('@/views/orders/List.vue'),
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/orders/export',
    name: 'OrdersExport',
    component: () => import('@/views/orders/Export.vue'),
    meta: {
      requiresAuth: true,
      layout: LayoutSidebar,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: LayoutDefault,
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import('../views/About.vue'),
    meta: {
      layout: LayoutSidebar,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) =>
{
  if (to.matched.some(record => record.meta.requiresAuth))
  {
    if (getCookie('token'))
    {
      next()
      return
    }
    router.replace({ name: 'Login' })
  }
  else
  {
    next()
  }
})

export default router
