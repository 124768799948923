import http from '@/plugins/http-common'
import {FileTypes, OrderByVariants} from './constants'
import {ResponseData} from './types'


export type GetsListParams = {
    limit: null | number,
    offset: null | number,
    order_by: null | OrderByVariants,
    sort_by: null | string,
    file_types: FileTypes[]
}

class FileService {
    getFileById = (fileId: number): Promise<ResponseData> =>
        http.get(`/files/${fileId}`, {responseType: 'blob'})

    getPassport = (fileId: number): Promise<ResponseData> =>
        http.get(`/files/passport/${fileId}`, {responseType: 'blob'})

    getFileList = (params: GetsListParams): Promise<ResponseData> => http.get('/files', {params})
}

export default new FileService()




