import json from '@/env.json'

type ConfigJson = {
    runtime: {
        backend_url: string
    }
}


export default function getConfig(): ConfigJson {

    if (json) {
        return json as ConfigJson
    }

    return {
        runtime:{
            backend_url: 'http://0.0.0.0:8001/v2/admin'
        }
    }

}
