import router from '@/router'
import {ResponseData} from '@/services/types'
import AuthService from '@/services/AuthService'
import {ref} from '@vue/reactivity'
import {AxiosError} from 'axios'
import {setCookie} from '../utils'
import {Token} from './account-models'
import {User} from '../user/user-models'
import {Passport} from '../agents/agents-models'
import ErrorBag from '@/composables/error'

export default function useAuth() {
    const isAuthUserLoading = ref(false)
    const token = ref({} as Token)
    const user = ref({} as User)
    const passport = ref({} as Passport)
    const authUserErrorBag = ref(new ErrorBag)

    function authUser(login: string, password: string): void {
        isAuthUserLoading.value = true
        AuthService.authUser(login, password)
            .then((response: ResponseData) => {
                setUser(response.data.data.user)
                setToken(response.data.data.token)
                setPassport(response.data.data.passport)
                setCookie('token', response.data.data.token.access_token)
                router.push('/agents')
            })
            .catch((e: AxiosError) => {
                authUserErrorBag.value.bag = e.response?.data
            })
            .finally(() => {
                isAuthUserLoading.value = false
            })
    }

    function setUser(userData: User): void {
        user.value = userData
    }

    function setToken(tokenData: Token): void {
        token.value = tokenData
    }

    function setPassport(passportData: Passport): void {
        passport.value = passportData
    }

    return {
        // getters
        token,
        user,
        isAuthUserLoading,
        authUserErrorBag,

        // mutators
        setToken,
        setUser,
        setPassport,

        // actions
        authUser,
    }
}
