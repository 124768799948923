import {ref} from '@vue/reactivity'
// import {AxiosError} from 'axios'
// import {showError} from '@/composables/utils'
// import AgentsService, {GetListParams as Params} from '@/services/AgentsService'
import {Ref, UnwrapRef} from 'vue'
import {OrderByVariants} from '@/services/constants'
import ChatService, {GetListParams} from '@/services/ChatService'
import {ResponseData} from '@/services/types'
import {showError} from '@/composables/utils'
import {AxiosError} from 'axios'
import {User} from '@/composables/user/user-models'
import {File as FileModel} from '../file/models'

export interface ChatMessage {
    id: number
    text: string
    is_read: boolean
    created_at: string
    from_agent: boolean
    user: User,
    attachments: FileModel[]
}

const defaultTemplates = [
    'Приветствую!',
    'Как жизнь молодая?',
    'Мы обязательно с Вами свяжемся',
]

const testChatMessages: ChatMessage[] = [
    {
        id: 1,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Привет',
        from_agent: false,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 2,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Здравствуйте, Владимир! Сейчас всё проверим.',
        from_agent: true,
        user: {
            id: 2,
            name: 'test user',
            email: 'test@a.a'
        },
        attachments: [],
    },
    {
        id: 3,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Такой вопрос, статус выплаты в процессе. Это означает что средства не перечислены? Или они перечислены а статус в процессе остался?',
        from_agent: false,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 4,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Здравствуйте, Даниил! Сумму можно выводить только кратно 2000р',
        from_agent: true,
        user: {
            id: 2,
            name: 'test user',
            email: 'test@a.a'
        },
        attachments: [],
    },
    {
        id: 5,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Здравствуйте, подскажите как вывести 500 рублей, если меньше 2000 нельзя выводить?',
        from_agent: false,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 6,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Раздавайте свой скидочный промокод друзьям, тем самым накапливайте сумму от 2000р и все выводите',
        from_agent: true,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 7,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Как заказать выплату? Кнопка неактивна',
        from_agent: false,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 8,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'Добрый день. Пожалуйста, исправьте ФИО в соответствии с документом.',
        from_agent: true,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
    {
        id: 9,
        created_at: '2021-09-18 06:59:37',
        is_read: true,
        text: 'С первой заявки на вывод средств прошло 7 дней',
        from_agent: false,
        user: {
            id: 1,
            name: 'agent',
            email: 'agent@a.a'
        },
        attachments: [],
    },
]

let timer: ReturnType<typeof setTimeout>


/**************
 * META
 **************/
export type Meta = {
    limit: number,
    offset: number,
    sort_by: string,
    order_by: OrderByVariants,
    total: number,
    page: {
        current: number,
        total: number,
    },
}

enum SortByVariants {
    CREATED_AT = 'created_at',
}

const MetaDefault = {
    limit: 10,
    offset: 0,
    order_by: OrderByVariants.DESC,
    sort_by: SortByVariants.CREATED_AT,
    total: 0,
    page: {
        current: 1,
        total: 1,
    }
} as Meta


/***********
 * MAIN
 **********/
export interface ChatInterface {
    messages: Ref<UnwrapRef<{ [i: string]: ChatMessage }>>
    templates: Ref<UnwrapRef<string[]>>
    isLoading: Ref<UnwrapRef<boolean>>
    unreadMessagesCount: Ref<UnwrapRef<number>>
    startChatUpdate: () => void
    stopChatUpdate: () => void
    sendWritingMessage: () => void
}

export default function chat() {
    const messages = ref([] as ChatMessage[])
    const isLoading = ref(false)
    const isReading = ref(false)
    const templates = ref(defaultTemplates)
    const unreadMessagesCount = ref(0)
    const meta = ref(MetaDefault)
    const agentId = ref()

    function startChatUpdate(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // timer = setInterval(function() {
        //   console.log('chatUpdate')
        // }, 1000)
    }

    function stopChatUpdate(): void {
        if (timer) {
            clearInterval(timer)
        }
        console.log('stopChatUpdate')
    }


    function setAgentId(id: number): void {
        agentId.value = id
    }

    function setUnreadMsgCount(count: number): void {
        unreadMessagesCount.value = count
    }

    function fetch(): void {
        const params = {
            limit: meta.value.limit,
            offset: meta.value.offset,
            agent_id: agentId.value
        } as GetListParams

        isLoading.value = true
        ChatService.getMessages(params)
            .then((response: ResponseData) => {
                meta.value = response.data.data.meta
                messages.value.push(...response.data.data.items)
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isLoading.value = false)
    }

    function fetchNext(): void {
        if (isLoading.value) return

        const count = messages.value.length

        if (count >= meta.value.total) return

        isLoading.value = true
        meta.value.offset = count
        fetch()
    }

    function send(msg: string, attachments: File[] = []): void {
        isLoading.value = true

        const formData = new FormData()

        /*
            Add the form data we need to submit
        */
        formData.append('text', msg)

        for (let i = 0; i < attachments.length; i++) {
            formData.append('attachments[]', attachments[i])
        }

        ChatService.addMessage(agentId.value, formData)
            .then((response: ResponseData) => {
                messages.value.unshift(response.data.data)
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isLoading.value = false)
    }

    function read(): void {

        if (isReading.value) return

        isReading.value = true
        const found = messages.value.find((msg: ChatMessage) => {
            return !msg.is_read && msg.from_agent
        })

        if (found) {
            ChatService.readMessages(agentId.value)
                .then(() => {
                    messages.value.map(m => m.from_agent ? m.is_read = true : 0)
                })
                .finally(() => {
                    isReading.value = false
                })
        }
    }

    function sync(): void {
        ChatService.getStatus(agentId.value).then((r) => {
            unreadMessagesCount.value = r.data.data.unread_count
        })
    }

    function update(id: number, text: string) {
        ChatService.editMessage(id, text)
    }


    return {
        // actions
        fetch,
        fetchNext,
        send,
        read,
        sync,
        update,

        // getters
        messages,
        isLoading,
        templates,
        unreadMessagesCount,

        // setters
        setAgentId,
        setUnreadMsgCount,
        startChatUpdate,
        stopChatUpdate,
    }
}