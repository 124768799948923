import {Token} from '@/composables/account/account-models'
import http from '@/plugins/http-common'
import {ResponseData} from './types'

class AuthService {
    authUser = (login: string, password: string): Promise<ResponseData> => http.post('/auth/login', {login, password})

    refreshToken = (refresh_token: string): Promise<Token> => http.post('/auth/refresh', {refresh_token})
}

export default new AuthService()