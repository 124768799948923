import axios, {AxiosError} from 'axios'
import getConfig from '@/plugins/config'
import {getCookie} from '@/composables/utils'
import router from '@/router'

const CSRF_URL = '/auth/csrf'

console.warn(getConfig())

const http = axios.create({
    baseURL: getConfig().runtime.backend_url,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: '',
    },
})

initHttp()


function setTriggerToRequest() {
    http.interceptors.request.use(async function (config) {

        if (config.headers && config.headers['X-CSRF-TOKEN'] === undefined && config.url !== CSRF_URL) {

            const csrf_token = getCookie('XSRF-TOKEN')
            if (csrf_token) {
                config.headers['X-CSRF-TOKEN'] = csrf_token
            } else {
                await http.get(CSRF_URL)
                config.headers['X-CSRF-TOKEN'] = getCookie('XSRF-TOKEN') + ''
            }
        }


        const token = getCookie('token')
        if (config.headers && token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config
    })
}


function setTriggerToResponse() {
    http.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    }, function (error: AxiosError) {

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response?.status === 401) {
            router.replace({ name: 'Login' })
        }
        if (error.response?.status === 503) {
            console.warn('maintenance event')
        }
        if (error.message.toLowerCase() === 'network error') {
            console.warn('network error event')
        }
        if (error.response?.status === 419) {
            console.warn('x-csrf mismatch event')
        }

        return Promise.reject(error)
    })
}


function initHttp() {
    setTriggerToRequest()
    setTriggerToResponse()
}


export default http
