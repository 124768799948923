
import {Vue} from 'vue-class-component'
import {RouteLocationNormalized} from 'vue-router'

type SidebarItem = {
  name: string;
  display_name: string;
  icon: string;
  active: boolean;
  link: string;
  items: SidebarItem[];
};

export default class TheSidebar extends Vue {
  isReduced = false;

  sidebarItems: SidebarItem[] = [
    {
      name: 'home',
      display_name: 'Домой',
      icon: 'house-user',
      active: false,
      link: '/',
      items: [],
    },
    {
      name: 'agents',
      display_name: 'Агенты',
      icon: 'user-secret',
      active: false,
      link: '/agents',
      items: [
        {
          name: 'agents_list',
          display_name: 'Список агентов',
          icon: '',
          active: false,
          link: '/agents',
          items: [],
        },
        {
          name: 'agents_list',
          display_name: 'На модерацию',
          icon: '',
          active: false,
          link: '/agent_moderation',
          items: [],
        },
        {
          name: 'messages',
          display_name: 'Сообщения',
          icon: 'envelope',
          active: false,
          link: '/messages/dialogs',
          items: [],
        },
      ],
    },
    {
      name: 'orders',
      display_name: 'Заказы',
      icon: 'money-bill-alt',
      active: false,
      link: '/orders',
      items: [
        {
          name: 'orders_list',
          display_name: 'Список заказов',
          icon: '',
          active: false,
          link: '/orders',
          items: [],
        },
        {
          name: 'orders_export',
          display_name: 'Экспорт',
          icon: '',
          active: false,
          link: '/orders/export',
          items: [],
        }
      ],
    },
    {
      name: 'about',
      display_name: 'О нас',
      icon: 'comment',
      active: false,
      link: '/about',
      items: [],
    }
  ];


  toggleItemActive(item: SidebarItem): void {
    // Чтобы разделить логику выделения в зависимости от состояния.
    // При свернутом должна работать через hover
    if (!this.isReduced) item.active = !item.active
  }

  renderItems(route: RouteLocationNormalized) {
    this.sidebarItems.forEach((item: SidebarItem) => {

      item.active = item.link.length > 1 ? !!route.path.match(new RegExp(item.link)) : route.path === item.link


      item.items.forEach((sub: SidebarItem) => {
        sub.active = route.path === sub.link

        if (sub.active) item.active = true
      })
    })
  }

  beforeMount(): void {
    let key = sessionStorage.getItem('ui_sidebar__is-reduced')
    if (key) {
      this.isReduced = JSON.parse(key)
    }
  }

  mounted(): void {
    this.$router.afterEach(this.renderItems)
    this.renderItems(this.$route)
  }

  toggle(): void {
    this.isReduced = !this.isReduced
    sessionStorage.setItem('ui_sidebar__is-reduced', JSON.stringify(this.isReduced))

    // Сбросить выделения. Т.к. логика меняется на hover
    this.renderItems(this.$route)
  }
}
