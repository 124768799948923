
import {setup, Vue} from 'vue-class-component'
import {Modal} from '@/composables/core/modal'
import useEmitter from '@/composables/useEmitter'


export default class ModalWindow extends Vue {
  ctx = setup(useEmitter)

  modal: Modal|null = null

  created() {
    this.ctx.emitter.on('modal:show', this.setModal)
  }

  setModal(modal: Modal) {
    this.modal = modal
    this.modal.title = this.modal.title ?? 'Подтверждение действия'
  }

  success() {
    if (this.modal !== null && this.modal.onsuccess) {
      this.modal.onsuccess()
    }
    this.modal = null
  }

  exit() {
    if (this.modal !== null && this.modal.onexit) {
      this.modal.onexit()
    }
    this.modal = null
  }

  beforeUnmount(): void {
    this.ctx.emitter.off('modal:show')
  }
}
