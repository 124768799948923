import router from '@/router'
import {AxiosError} from 'axios'

export const STORAGE_PREFIX = 'mlm_'

export const keyBy = (pk: string, collection: any[]): { [idx: number]: any } => {
  const keyedCollection: { [idx: number]: any } = {}
  collection.forEach(item => keyedCollection[item[pk]] = item)
  
  return keyedCollection
}

export const showError = (error: AxiosError): void => {
  console.log(error.response)
}

export const setCookie = (name: string, value: string) => {
  const now = new Date()
  const time = now.getTime()
  const expireTime = time + 1000*36000
  now.setTime(expireTime)
  document.cookie = `${name}=${value};expires=${now.toUTCString()};path=/`
}

export const setLocalStorageItem = (itemName: string, itemValue: string) => {
  localStorage.setItem(`${STORAGE_PREFIX}${itemName}`, itemValue)
}

export const getLocalStorageItem = (itemName: string) => {
  return localStorage.getItem(`${STORAGE_PREFIX}${itemName}`)
}

export function getCookie(name: string) {
  const match_list = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'),
  )
  return match_list ? decodeURIComponent(match_list[1]) : undefined
}