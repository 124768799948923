import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal is-active"
}
const _hoisted_2 = { class: "modal-card" }
const _hoisted_3 = { class: "modal-card-head" }
const _hoisted_4 = { class: "modal-card-title" }
const _hoisted_5 = { class: "modal-card-body" }
const _hoisted_6 = { class: "body-text" }
const _hoisted_7 = { class: "modal-card-foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.modal !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "modal-background",
          "aria-label": "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exit && _ctx.exit(...args)))
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.modal.title), 1),
            _createElementVNode("button", {
              class: "delete",
              "aria-label": "close",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exit && _ctx.exit(...args)))
            })
          ]),
          _createElementVNode("section", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.modal.text), 1)
          ]),
          _createElementVNode("footer", _hoisted_7, [
            _createElementVNode("button", {
              class: "button is-success",
              "aria-label": "success",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.success && _ctx.success(...args)))
            }, "Принять"),
            _createElementVNode("button", {
              class: "button is-white has-text-grey",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.exit && _ctx.exit(...args)))
            }, "Отмена")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}