import http from '@/plugins/http-common'
import {OrderByVariants} from './constants'
import {ResponseData} from './types'


export type GetListParams = {
    limit: null | number,
    offset: null | number,
    order_by: null | OrderByVariants,
    sort_by: null | string,
    agent_id: number,
}

class ChatService {
    getMessages = (params: GetListParams): Promise<ResponseData> => http.get(`/agents/${params.agent_id}/messages`, {params})

    addMessage = (agent_id: number, text: FormData): Promise<ResponseData> => http.post(`/agents/${agent_id}/messages`, text)

    editMessage = (messageId: number, text: string): Promise<ResponseData> => http.post(`/messages/${messageId}/update`, {text})

    removeMessage = (messageId: number): Promise<ResponseData> => http.delete(`/messages/${messageId}`)

    readMessages = (agent_id: number): Promise<ResponseData> => http.post(`/agents/${agent_id}/messages/read`)

    getStatus = (agent_id: number): Promise<ResponseData> => http.get(`/agents/${agent_id}/messages/status`)

    sendFile = (file: any, userId: number) => http.post('/bla-bla-bla', {file, userId})
}

export default new ChatService()