export enum OrderByVariants {
    ASC = 'asc',
    DESC = 'desc',
}

export enum FileTypes {
    EXPORT = 1,
    PAGE_GENERAL = 2,
    PAGE_LIVING = 3
}

export enum TypeModalWindow {
    INFO = 'info',
    WARNING = 'warning'
}

export enum ActionModalWindow {
    CONFIRM = 'confirm',
    CANCEL = 'cancel',
}


// interface ENV {
//     BACKEND_URL: string
// }

// declare const APP_ENV: ENV
// export const __ENV__ = APP_ENV
//
//
// // export const API_BASE_URL = 'https://mlmapi.mpk-dev.ru/v2/admin'
// export const API_BASE_URL = APP_ENV.BACKEND_URL ?? 'http://0.0.0.0:8001/v2/admin'