
import { Options, Vue, setup } from 'vue-class-component'
import useEmitter from '@/composables/useEmitter'
import useAccount from '@/composables/account'

@Options({
})
export default class TheNavbar extends Vue {

  navbarContext = setup(() => {
    const { emitter } = useEmitter()
    const { token, user } = useAccount()
    return { emitter, token, user }
  })

  toggleReduced(): void {
    this.navbarContext.emitter.emit('sidebar-reduced:change')
  }
}
